<template>
  <div>

  <van-empty v-if="!org" description="小区未开通申报" />
  <div v-else>


    <van-nav-bar :title="org.name+' 人员申报'" />

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="username"
            name="name"
            label="姓名"
            placeholder="姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
        />

        <van-field
            v-model="phone"
            name="phone"
            label="手机号码"
            type="tel"
            placeholder="手机号码"
            :rules="[{ pattern:/^1\d{10}$/i, required: true, message: '请填写手机号码' }]"
        />
        <van-field
            v-model="idCard"
            name="idCard"
            label="身份证"
            placeholder="身份证"
            :rules="[{ pattern:/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i, required: true, message: '请填写身份证号码' }]"
        />

        <van-field name="face" label="照片">
          <template #input>
            <van-uploader v-model="face"
                          multiple :max-count="1"
                          capture="camera"
                          image-fit="cover"
                          :before-read="beforeRead"
                          :result-type="'dataUrl'" />
          </template>
        </van-field>

      </van-cell-group>
      <div style="margin: 16px;">
        <van-button :loading="loading" round block type="primary" native-type="submit" loading-text="提交中...">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
  </div>
</template>

<script>

import Compressor from 'compressorjs';
import {Dialog} from "vant";

export default {
  name: "PostPerson",
  props: ['code'],
  data(){
    return {
      phone:"",
      username:"",
      face:[],
      idCard:"",
      loading:false,
      org:null
    }
  },
  created() {
    console.log(this.$route.query.code);
    let code =this.$route.query.code;
    this.getVlName(code).then((res)=>{
      if(res.body.code!=0){
        this.org=null
        return;
      }
      this.org=res.body.data;
    })
  },
  methods:{

    beforeRead : (file) =>new Promise((resolve) => {
      new Compressor(file, {
        success: resolve,
        error(err) {
          console.log(err.message);
        },
      });
    }),

    onSubmit:function(values,isUpdate){
      if(this.loading) return;
      this.loading=true;
      console.log(JSON.stringify(values.face[0].content))
      console.log('submit', values);
      this.postPerson({
        phone:this.phone,
        name:this.username,
        idCard:this.idCard,
        face:values.face[0].content,
        vid:this.org.id
      },isUpdate).then((res)=>{
        console.log(res)
        if(res.body.code==6){
          return Dialog.confirm({
            title: '提示',
            message:'系统已有您的记录，是否要更新信息'
          }).then(()=>{
            this.loading=false;
            this.onSubmit(values,true);
          }).catch(()=>{

          });
        }else if(res.body.code!=0){
          return Dialog({ message: res.body.msg });
        }else{
          return Dialog({message:'提交成功,请等待审核!'});
        }
      }).finally(()=>{
          setTimeout(()=>{this.loading=false;},300)

      });
    }
  }
}
</script>

<style scoped>

</style>
