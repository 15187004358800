const host=location.host.indexOf("techzhl.com")!==-1?"https://vl.techzhl.com":"http://localhost:8080";

export default {
    install(Vue){

        Vue.prototype.postPerson=(person,isUpdate)=>{
            console.log("hello")
            if(isUpdate) return Vue.http.post(host+"/pf/update_person",person)
            return Vue.http.post(host+"/pf/post_person",person)
        }

        Vue.prototype.getVlName=(code)=>{
            return Vue.http.post(host+"/pf/get_vl_name", {code})
        }

    }
}
