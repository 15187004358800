<template>
  <PostPerson />
</template>

<script>
import PostPerson from './components/PostPerson.vue'

export default {
  name: 'App',
  components: {
    PostPerson
  }
}
</script>

<style>

</style>
