import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import api from './common/api'
import 'vant/lib/index.css';
import routers from './router/index'

Vue.use(Vant);
Vue.use(VueResource)
Vue.use(api)
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router:new VueRouter(routers)
}).$mount('#app')
