import PostPerson from "@/components/PostPerson";

export default {
    mode: 'history',
    routers:[
        {
            path:"/postPerson",
            name:'PostPerson',
            component:PostPerson,
            props:true
        }
    ]
}
